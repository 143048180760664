import React from "react";
import { Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { getAgreements } from "../../services/AgreementServices";
import { CustomNoRowsOverlay } from "../../components/TableNoRowsOvlery";
import Edit from "@mui/icons-material/Edit";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { setModelToAgreements } from "../../models/AgreementModel";
import { useTableRowContext } from "../../context/TableRowContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import clsx from "clsx";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditContainerAgreementModal from "../Modals/EditAgreementModal/EditAgreementModal";
import ApproveChassisAgreement from "../Modals/ApproveChassisAgreement/ApproveChassisAgreement";
import Cookies from "universal-cookie";
import { useSnackBar } from "../../context/SnackBarContext";
import "./AgreementTable.css";
const AgreementTable = () => {
  const [agreements, setAgreements] = React.useState({
    allRows: [],
    rows: [],
  });
  const { handleRenderSnackBar } = useSnackBar();
  const cookies = new Cookies();
  const [dialogInformation, setDialogInformation] = React.useState({
    show: false,
    handleFunction: (e) => handleCloseDialog(),
    row: {},
    type: "",
  });
  const [fetchingData, setFetchingData] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    show: false,
    row: {},
    handleFunction: (e) => handleCloseModal(),
  });
  const { showAllColumns, search } = useTableRowContext();

  function handleEdit(row) {
    return function (e) {
      setModalInfo({
        show: true,
        row: row,
        handleFunction: (e) => handleCloseModal(),
      });
    };
  }

  function handleRedirectToChassisRental(row) {
    if (row.status === "Not Active") {
      return function (e) {
        window.history.pushState(
          {},
          "",
          window.location.origin +
            "/landing-page/custom-agreement?CustomAgreement=" +
            row.cod
        );
      };
    }

    return function (e) {
      window.history.pushState(
        {},
        "",
        window.location.origin +
          "/yms-chassis-portal/chekcinout?CarrierScacCode=" +
          row.scac_code +
          "&AgreementId=" +
          row.cod
      );
    };
  }

  function handleCopyLinkToChassisRental(row) {
    if (row.status === "Not Active") {
      return function (e) {
        navigator.clipboard.writeText(
          window.location.origin +
            "/landing-page/custom-agreement?CustomAgreement=" +
            row.cod
        );
        handleRenderSnackBar(
          "Link to pay chassis rental with ScacCode: " +
            row.scac_code +
            " Copied to clipboard",
          "success"
        );
      };
    }
    return function (e) {
      navigator.clipboard.writeText(
        window.location.origin +
          "/yms-chassis-portal/chekcinout?CarrierScacCode=" +
          row.scac_code +
          "&AgreementId=" +
          row.cod
      );
      handleRenderSnackBar(
        "Link to rent chassis with ScacCode: " +
          row.scac_code +
          " Copied to clipboard",
        "success"
      );
    };
  }

  function handleCloseDialog() {
    setDialogInformation((dialogInformation) => ({
      show: false,
      row: {},
      handleFunction: (e) => handleCloseDialog(),
    }));
  }

  const someColumns = [
    {
      align: "center",
      field: "actions",
      type: "actions",
      minWidth: 90,
      getActions: (params) =>
        [
          params.row.service_type !== "Chassis" && (
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={handleEdit(params)}
            />
          ),
        ].filter(Boolean),
    },
    // {
    //   flex: 1,
    //   field: "cod",
    //   headerName: "COD",
    //   minWidth: 150,
    //   header: "center",
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      flex: 1,
      field: "company",
      headerName: "Company",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scac_code",
      headerName: "SCAC Code",
      minWidth: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 140,
      renderCell: (params) => (
        <Typography
          className={`AgreementList-AgreementTable-status-column-${params.value.toLowerCase()} weight-up`}
          onClick={(e) => {
            navigator.clipboard.writeText(
              window.location.origin +
                "/landing-page/custom-agreement/CustomAgreement=" +
                params.row.cod
            );
            handleRenderSnackBar("Copied to clipboard", "success");
          }}
        >
          {params.value}
        </Typography>
      ),
      cellClassName: (params) => {
        const status = params.value.toLowerCase(); // Convertir a minúsculas
        return clsx({
          "AgreementList-AgreementTable-status-column-active":
            status === "activated",
          "AgreementList-AgreementTable-status-column-not-active":
            status === "not active",
        });
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      cellClassName: (params) => {
        return clsx("AgreementList-AgreementTable-type-column");
      },
      headerAlign: "center",
      align: "center",
    },

    {
      field: "service_type",
      headerName: "Service Type",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spots",
      headerName: "# of Spots",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "loc_chassis",
      headerName: "Loc/Chassis",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "over_price",
      headerName: "Over. Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "over_chassis",
    //   headerName: "Over. Chassis",
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "billing_start",
      headerName: "Billing Start",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chassisAction",
      headerName: "Chassis Rented",
      minWidth: 150,
      align: "center",
      renderCell: (params) => (
        <>
          {params.row.service_type === "Chassis" &&
            (params.row.status === "Activated" ||
              params.row.status === "Not Active") && (
              <>
                <GridActionsCellItem
                  icon={<ContentPasteIcon />}
                  label="Copy"
                  onClick={handleCopyLinkToChassisRental(params.row)}
                />{" "}
                <GridActionsCellItem
                  icon={<OpenInNewIcon />}
                  label="Redirect"
                  onClick={handleRedirectToChassisRental(params.row)}
                />
              </>
            )}
          {params.row.service_type === "Chassis" &&
            params.row.status === "Created" && (
              <>
                <GridActionsCellItem
                  icon={<AssignmentTurnedInIcon />}
                  label="Aprove Agreement"
                  onClick={() =>
                    setDialogInformation({
                      show: true,
                      row: params.row,
                      handleFunction: (e) => handleCloseDialog(),
                    })
                  }
                />{" "}
              </>
            )}
          {params.row.service_type === "Chassis" &&
            params.row.status === "Activated" && (
              <>
                <GridActionsCellItem
                  icon={<AttachFileIcon />}
                  label="Aprove Agreement"
                  onClick={() =>
                    setDialogInformation({
                      show: true,
                      row: params.row,
                      handleFunction: (e) => handleCloseDialog(),
                      type: "addChassis",
                    })
                  }
                />{" "}
              </>
            )}
        </>
      ),
    },
  ];
  const allColumns = [
    {
      field: "actions",
      type: "actions",
      minWidth: 90,
      align: "center",
      getActions: (params) =>
        [
          params.row.service_type !== "Chassis" && (
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={handleEdit(params)}
            />
          ),
        ].filter(Boolean),
    },
    {
      field: "chassisAction",
      headerName: "Chassis Rented",
      minWidth: 150,
      align: "center",
      renderCell: (params) => (
        <>
          {params.row.service_type === "Chassis" &&
            (params.row.status === "Activated" ||
              params.row.status === "Not Active") && (
              <>
                <GridActionsCellItem
                  icon={<ContentPasteIcon />}
                  label="Copy"
                  onClick={handleCopyLinkToChassisRental(params.row)}
                />{" "}
                <GridActionsCellItem
                  icon={<OpenInNewIcon />}
                  label="Redirect"
                  onClick={handleRedirectToChassisRental(params.row)}
                />
              </>
            )}{" "}
          {params.row.service_type === "Chassis" &&
            params.row.status === "Created" && (
              <>
                <GridActionsCellItem
                  icon={<AssignmentTurnedInIcon />}
                  label="Aprove Agreement"
                  onClick={() =>
                    setDialogInformation({
                      show: true,
                      row: params.row,
                      handleFunction: (e) => handleCloseDialog(),
                    })
                  }
                />{" "}
              </>
            )}{" "}
          {params.row.service_type === "Chassis" &&
            params.row.status === "Activated" && (
              <>
                <GridActionsCellItem
                  icon={<AttachFileIcon />}
                  label="Aprove Agreement"
                  onClick={() =>
                    setDialogInformation({
                      show: true,
                      row: params.row,
                      handleFunction: (e) => handleCloseDialog(),
                      type: "addChassis",
                    })
                  }
                />{" "}
              </>
            )}
        </>
      ),
    },
    // {
    //   field: "cod",
    //   headerName: "COD",
    //   minWidth: 150,
    //   header: "center",
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scac_code",
      headerName: "SCAC Code",
      minWidth: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 140,
      renderCell: (params) => (
        <Typography
          className={`AgreementList-AgreementTable-status-column-${params.value.toLowerCase()} weight-up`}
          onClick={(e) => {
            navigator.clipboard.writeText(
              window.location.origin +
                "/landing-page/custom-agreement/CustomAgreement=" +
                params.row.cod
            );
            handleRenderSnackBar("Copied to clipboard", "success");
          }}
        >
          {params.value}
        </Typography>
      ),
      cellClassName: (params) => {
        const status = params.value.toLowerCase(); // Convertir a minúsculas
        return clsx({
          "AgreementList-AgreementTable-status-column-active":
            status === "activated",
          "AgreementList-AgreementTable-status-column-not-active":
            status === "not active",
        });
      },
      headerAlign: "center",
      align: "center",
    },

    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      cellClassName: (params) => {
        return clsx("AgreementList-AgreementTable-type-column");
      },
      headerAlign: "center",
      align: "center",
    },

    {
      field: "billing_address",
      headerName: "Billing Address",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service_type",
      headerName: "Service Type",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spots",
      headerName: "# of Spots",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "loc_chassis",
      headerName: "Loc/Chassis",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "over_price",
      headerName: "Over. Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "over_chassis",
    //   headerName: "Over. Chassis",
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "contact_name",
      headerName: "Contact Name",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billing_start",
      headerName: "Billing Start",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "billing_end",
    //   headerName: "Billing End",
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "st_contact",
      headerName: "ST Contact",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "st_phone_number",
      headerName: "ST Phone Number",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  function handleCloseModal() {
    setModalInfo((modalInfo) => ({
      show: false,
      row: {},
      handleFunction: (e) => handleCloseModal(),
    }));
  }

  function handleFetchData() {
    setFetchingData(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    if (!sessionCosmos) {
      window.addEventListener("userInB2C", () => {
        handleFetchData();
      });
      return;
    }
    getAgreements(sessionCosmos.scac_code).then((data) => {
      const agreementList = setModelToAgreements(data);
      setAgreements({
        allRows: agreementList,
        rows: agreementList,
      });
      setFetchingData(false);
    });
    // getAgreements("SNEA").then((data) => {
    //   const agreementList = setModelToAgreements(data);
    //   setAgreements({
    //     allRows: agreementList,
    //     rows: agreementList,
    //   });
    //   setFetchingData(false);
    // });
  }

  function handleFilterBySearch() {
    if (!agreements) return;
    const agreementsNew = agreements.allRows;
    const filteredAgreements = agreementsNew.filter((agreement) => {
      const agreementString = Object.values(agreement).join(" ").toLowerCase();
      return agreementString.includes(search.toLowerCase());
    });
    setAgreements((agreements) => ({
      ...agreements,
      rows: filteredAgreements,
    }));
  }

  React.useEffect(() => {
    handleFetchData();
    window.addEventListener("reloadData", () => {
      handleFetchData();
    });
  }, []);

  React.useEffect(() => {
    handleFilterBySearch();
  }, [search]);

  const getRowId = (row) => row.cod;
  return (
    <>
      <section className="AgreementList-AgreementTable-container">
        <Typography
          variant="h5"
          className="AgreementList-AgreementTable-table-title"
        >
          Agreement List
        </Typography>
        <div className="AgreementList-AgreementTable-table-class">
          <DataGrid
            className="AgreementList-AgreementTable-table-class"
            getRowId={getRowId}
            rows={agreements.rows || []}
            columns={showAllColumns ? allColumns : someColumns}
            disableSelectionOnClick
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,

              toolbar: GridToolbar,
            }}
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            loading={fetchingData}
          />
        </div>
      </section>
      <EditContainerAgreementModal
        modalInfo={modalInfo}
        handleReloadData={() => handleFetchData()}
      />
      <ApproveChassisAgreement
        dialogInformation={dialogInformation}
        handleReloadData={() => handleFetchData()}
      />
    </>
  );
};

export default AgreementTable;
